<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget
      .widget-header
        h1.title Publisher Postbacks
        i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'publisherPostbackLog'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            ov-date-time-picker(v-model="filters.datePicker")
            select-advertiser(v-model="filters.advertiser_id")
            select-offer(v-model="filters.offer_id")
            select-publisher(v-model="filters.publisher_id")
            select-placement(v-model="filters.placement_id")
            .form-group
              label Type
              select.form-control(v-model="filters.type")
                option(v-for="o in typeOptions", :value="o.v") {{ o.t }}
            .form-group
              label Status
              select.form-control(v-model="filters.status")
                option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
            .form-group.no-label
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download
                    | Export

        p.clearfix &nbsp;

        .ov-table-wrapper
          paginate(:paginator="paginate", @update-page-size="loadData")
          table.table.table-bordered
            thead
              tr
                th ID
                th Status
                th Type
                th Publisher
                th URL
                th HTTP Status
                th Response
                th Error
                th Created
                //th Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="10") No matching records were found
              tr(v-for="r in records", v-bind:key="r.id")
                td {{ r.id }}
                td
                  .badge.badge-success(v-if="r.status===1") Sent
                  .badge.badge-warning(v-if="r.status===2") Retry
                  .badge.badge-danger(v-if="r.status===3") Error
                td
                  .badge.badge-primary(v-if="r.type==='event'") Event
                  .badge.badge-info(v-if="r.type==='conversion'") Install
                  .badge.badge-danger(v-if="r.type==='reject'") Rejection
                td
                  entity(v-if="r.publisher_id", :id="r.publisher_id", :name="r.publisher_name", type="publisher")
                td
                  textarea.form-control(:value="r.url", rows="2", style="min-width: 300px;")
                td {{ r.http_status }}
                td
                  textarea.form-control(:value="r.http_response", rows="2", style="min-width: 200px;")
                td {{ r.error_string }}
                td {{ r.created_at }}
                //td.actions
                  //a.btn.btn-sm.btn-secondary(href="")
                    i.la.la-trash

          paginate(:paginator="paginate", @update-page-size="loadData")
</template>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'PubliserPostback',
  async beforeRouteEnter(to, from, next) {
    let selectedAdvertiserOptions = await Vue.ovData.advertiser.getSelectedOptions(
      to.query.advertiser_id,
    );
    let selectedPublisherOptions = await Vue.ovData.publisher.getSelectedOptions(
      to.query.publisher_id,
    );
    let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(to.query.offer_id);
    let selectedPlacementOptions = await Vue.ovData.placement.getSelectedOptions(
      to.query.placement_id,
    );

    next(async (vm) => {
      vm.filters.offer_id = selectedOfferOptions;
      vm.filters.publisher_id = selectedPublisherOptions;
      vm.filters.placement_id = selectedPlacementOptions;
      vm.filters.advertiser_id = selectedAdvertiserOptions;

      vm.loadData();
      return vm;
    });
  },
  data() {
    const defaultFilters = {
      status: -1,
      type: 'all',
      keyword: '',
      offer_id: [],
      placement_id: [],
      publisher_id: [],
      advertiser_id: [],
      datePicker: {
        timezone: 0,
        dateRange: {
          startDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          endDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        },
      },
    };
    return {
      areFiltersOpened: false,
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      defaultFilters,
      filters: { ...defaultFilters },
      statusOptions: [
        { v: -1, t: 'All' },
        { v: 0, t: 'Pending' },
        { v: 1, t: 'Sent' },
        { v: 2, t: 'Retry' },
        { v: 3, t: 'Failed' },
      ],
      statusHash: {},
      typeOptions: [
        { v: 'all', t: 'All' },
        { v: 'conversion', t: 'Conversions Only' },
        { v: 'event', t: 'Events Only' },
        { v: 'reject', t: 'Rejected Only' },
      ],
      records: [],
    };
  },
  methods: {
    // updateRanges(){
    //
    // 	let tz = this.filters.timezone;
    // 	let localOffset = new Date().getTimezoneOffset() / 60;
    // 	let tzGap = (-localOffset) - tz;
    //
    // 	this.datePickerOptions.ranges = {
    // 		'This Hour': [
    // 			moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':59', 'DD/MM/YYYY HH:mm'),
    // 		],
    // 		'Last Hour': [
    // 			moment(moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':59', 'DD/MM/YYYY HH:mm'),
    // 		],
    // 		'Today': [
    // 			moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm'),
    // 		],
    // 		'Yesterday': [
    // 			moment(moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
    // 		],
    // 		'Last 7 Days': [
    // 			moment(moment().subtract(tzGap, 'hours').subtract(6, 'days').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
    // 		],
    // 		'Last 30 Days': [
    // 			moment(moment().subtract(tzGap, 'hours').subtract(29, 'days').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
    // 		],
    // 		'This Month': [
    // 			moment(moment().subtract(tzGap, 'hours').startOf('month').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').endOf('month').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
    // 		],
    // 		'Last Month': [
    // 			moment(moment().subtract(tzGap, 'hours').subtract(1, 'month').startOf('month').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
    // 			moment(moment().subtract(tzGap, 'hours').subtract(1, 'month').endOf('month').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
    // 		]
    // 	};
    // },

    async loadData(doExport) {
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        type: this.filters.type,
        status: +this.filters.status,
        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),

        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
      };
      try {
        let resp = await this.$ovReq.get('publisherPostback/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },

  created() {
    this.loadData();
    this.statusHash = this.statusOptions.reduce((acc, o) => {
      acc[o.v] = o.t;
      return acc;
    }, {});
  },
};
</script>
